import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import ThemeProvider from '@mui/material/styles/ThemeProvider';

import PageLoading from './components/common/PageLoading.jsx';
import Layout from './components/Layout.jsx';
import useCustomTheme from './hooks/customizeReactTheme.jsx';
import Home from './pages/Home/index.jsx';
import Page404 from './pages/Page404.jsx';
import { addAccessTokenInterceptor } from './axiosClient.js';

function App() {
  const { isLoading, isAuthenticated, error, loginWithRedirect, getAccessTokenSilently } =
    useAuth0();

  const theme = useCustomTheme();

  React.useEffect(() => {
    if (!isLoading && !isAuthenticated && !error) {
      // Use pathname for redirect - since we're staying within the application
      loginWithRedirect({
        appState: {
          returnTo: `${window.location.pathname}${window.location.search}`,
        },
      });
    }
  }, [isLoading, isAuthenticated, error, loginWithRedirect]);

  const handleClick = React.useCallback(
    () =>
      loginWithRedirect({
        appState: { returnTo: window.location.origin },
      }),
    [loginWithRedirect]
  );

  React.useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  return (
    <ThemeProvider theme={theme}>
      {error && (
        <Container maxWidth="lg">
          <div style={{ pb: '2em' }}>Oops... {error.message}</div>
          <Button variant="contained" onClick={handleClick}>
            Login
          </Button>
        </Container>
      )}
      {!error && (isLoading || !isAuthenticated) && <PageLoading />}
      {isAuthenticated && (
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<Page404 />} />
          </Routes>
        </Layout>
      )}
    </ThemeProvider>
  );
}

export default App;
