import React from 'react';

import ZoomInIcon from '@mui/icons-material/ZoomIn';

import Box from '@mui/material/Box';

const GLASS_MARGIN = 4;

function MagnifiableImage({ src, style, alt, onClick, index }) {
  const [displayIcon, setDisplayIcon] = React.useState(false);
  const handleMouseEnter = React.useCallback(() => setDisplayIcon(true), []);
  const handleMouseLeave = React.useCallback(() => setDisplayIcon(false), []);

  const divRef = React.useRef(null);

  const sxStyles = React.useMemo(() => {
    const height = divRef.current?.getBoundingClientRect().height ?? 0;
    const width = divRef.current?.getBoundingClientRect().width ?? 0;
    const backdropHeight = height ? `${height + GLASS_MARGIN * 2}px` : 0;
    const backdropWidth = width ? `${width + GLASS_MARGIN * 2}px` : 0;

    const left = divRef.current ? divRef.current.offsetLeft - GLASS_MARGIN : 0;
    const top = divRef.current ? divRef.current.offsetTop - GLASS_MARGIN : 0;

    return {
      height: backdropHeight,
      width: backdropWidth,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backdropFilter: 'blur(5px)',
      opacity: displayIcon ? 1 : 0,
      position: 'absolute',
      top,
      left,
      borderRadius: '0.5em',
    };
  }, [divRef, displayIcon]);

  return (
    <Box
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
      ref={divRef}
      sx={{ display: 'inline-block', '&:hover': { cursor: 'pointer' } }}
    >
      <img data-cy={`product-image-${index}`} src={src} alt={alt} style={style} loading="lazy" />
      <Box data-cy={`expand-product-image-button-${index}`} sx={sxStyles}>
        <ZoomInIcon fontSize="large" />
      </Box>
    </Box>
  );
}

export default React.memo(MagnifiableImage);
