import React from 'react';
import { useTheme } from '@emotion/react';

import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';

function ProductPictureStepper({
  images,
  productName,
  defaultStep = 0,
  onImageClick = null,
  width = 400,
  children,
}) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(defaultStep);
  const maxSteps = images?.length ?? 0;
  const handleNext = React.useCallback(
    (event) => {
      event.stopPropagation();
      setActiveStep((previous) => (previous + 1) % maxSteps);
    },
    [maxSteps]
  );

  const handlePrevious = React.useCallback(
    (event) => {
      event.stopPropagation();
      setActiveStep((previous) => (previous ? previous - 1 : maxSteps - 1));
    },
    [maxSteps]
  );

  const imageURL = React.useMemo(() => {
    if (!images || images?.length === 0) {
      return null;
    }
    return images[activeStep].URL;
  }, [images, activeStep]);

  const handleClick = React.useCallback(
    (event) => {
      event.stopPropagation();
      if (onImageClick) {
        onImageClick(event);
      }
    },
    [onImageClick]
  );

  return (
    <Box sx={{ flexGrow: 1 }} onClick={handleClick}>
      <MobileStepper
        sx={{
          borderTopRightRadius: '0.5em',
          borderTopLeftRadius: '0.5em',
        }}
        variant="dots"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <IconButton size="small" onClick={handleNext}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft color="secondary" />
            ) : (
              <KeyboardArrowRight color="secondary" />
            )}
          </IconButton>
        }
        backButton={
          <IconButton size="small" onClick={handlePrevious}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight color="secondary" />
            ) : (
              <KeyboardArrowLeft color="secondary" />
            )}
          </IconButton>
        }
      />
      <Paper
        square
        elevation={0}
        sx={{
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          borderBottomLeftRadius: '0.5em',
          borderBottomRightRadius: '0.5em',
        }}
      >
        <Box display="flex" flexDirection="column" justifyContent="center">
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
          <img
            onClick={handleClick}
            style={{ padding: 1, maxHeight: width, maxWidth: width }}
            src={imageURL}
            alt={productName}
          />
          {children}
        </Box>
      </Paper>
    </Box>
  );
}

export default ProductPictureStepper;
