import React from 'react';

import Box from '@mui/material/Box';

function ProductFeatureDisplay({ feature }) {
  const [featureName] = feature.split(':');
  const featureBody = feature.slice(featureName.length + 1);
  const featureBulletPoints = featureBody.split('\n\u2022').slice(1);

  return (
    <Box>
      <strong>{featureName}</strong>
      <ul>
        {featureBulletPoints.map((bullet) => (
          <li key={bullet}>{bullet}</li>
        ))}
      </ul>
    </Box>
  );
}

export default React.memo(ProductFeatureDisplay);
