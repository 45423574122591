import React from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';

import DimensionsDisplay from '../components/DimensionsDisplay.jsx';
import ExpandableImage from '../components/ExpandableImage.jsx';
import ProductFeatureDisplay from './ProductFeatureDisplay.jsx';

const SlideTransition = React.forwardRef(function SlideTransition(props, ref) {
  /* eslint-disable-next-line react/jsx-props-no-spreading */
  return <Slide direction="up" ref={ref} {...props} />;
});

function ProductDialog({ product, open, onClose }) {
  const sortedImages = React.useMemo(
    () => product?.images.toSorted((a, b) => a.URL.localeCompare(b.URL, 'en')),
    [product?.images]
  );

  const prettyPrice = React.useMemo(() => Number(product.tzPrice).toFixed(2), [product?.tzPrice]);
  const [bigPictureOpen, setBigPictureOpen] = React.useState(sortedImages.map(() => false));

  return (
    <Dialog
      keepMounted
      onClose={onClose}
      open={open}
      sx={{
        width: { xs: '100vw', md: '80vw' },
        height: { md: '95vh' },
        mx: 'auto',
        my: 'auto',
      }}
      TransitionComponent={SlideTransition}
      PaperProps={{
        sx: {
          borderRadius: { xs: 0, md: '0.75em' },
          backdropFilter: 'blur(10px)',
        },
      }}
      data-cy="product-dialog"
      fullScreen
    >
      <DialogTitle sx={{ display: { xs: 'none', md: 'block' } }}>
        <Box display="flex" alignItems="start" justifyContent="space-between">
          <Typography variant="h5" fontWeight="bold">
            {product.name}
          </Typography>
          <IconButton onClick={onClose} sx={{ display: { xs: 'none', md: 'flex' } }}>
            <ClearIcon />
          </IconButton>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent sx={{ overflowY: { xs: 'auto', md: 'hidden' }, px: '1rem' }}>
        <Box
          pt="0.75rem"
          display={{ xs: 'block', md: 'none' }}
          alignItems="start"
          justifyContent="space-between"
        >
          <Typography variant="h6" fontWeight="bold">
            {product.name}
          </Typography>
          <Divider />
        </Box>
        <Grid container spacing={2} width="100%" height="100%">
          <Grid item md={4} display={{ xs: 'none', md: 'block' }}>
            <Grid container>
              {/*
               * sort order of images is determined based on DBMS so we make it
               * deterministic on the frontend so we don't have issues in testing.
               */}
              {sortedImages.map(({ URL }, index) => (
                <Grid item xs={6} key={URL}>
                  <ExpandableImage
                    style={{ width: '10vw' }}
                    src={URL}
                    alt={product?.name}
                    images={sortedImages}
                    onClick={() => setBigPictureOpen((previous) => previous.with(index, true))}
                    onClose={() => setBigPictureOpen((previous) => previous.with(index, false))}
                    open={bigPictureOpen[index]}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            sx={{
              mt: '1em',
              pr: '0.5em',
              boxSizing: 'border-box',
              height: '100%',
              overflowY: { md: 'scroll' },
              pt: 0,
            }}
          >
            <Box sx={{ height: '100%', boxSizing: 'border-box' }}>
              <Box>
                <strong>Part Number:</strong> <em>{product.tzPartNumber}</em>
              </Box>
              {product?.warranty && (
                <Box>
                  <strong>Supplier PN:</strong> <em>{product.oemPartNumber}</em>
                </Box>
              )}
              {product?.warranty && (
                <Box>
                  <strong>Bed Manufacturer:</strong> <em>{product.manufacturer.name}</em>
                </Box>
              )}
              {product.model && (
                <Box>
                  <strong>Bed Model:</strong> {product.model}
                </Box>
              )}
              <Box>
                <strong>Price:</strong> ${prettyPrice}
                <Box>
                  <strong>Foam Type:</strong>
                  <Chip size="small" sx={{ ml: 1 }} label={product.foamType} />
                </Box>
              </Box>
              <DimensionsDisplay
                width={product.width}
                length={product.length}
                thickness={product.thickness}
              />
              {product?.warranty && (
                <Box>
                  <strong>Warranty:</strong>
                  <Typography whiteSpace="pre" ml="1em">
                    {product.warranty}
                  </Typography>
                </Box>
              )}
              {product?.description && (
                <details open data-cy="product-description">
                  <Box
                    component="summary"
                    sx={{
                      fontWeight: 'bold',
                      userSelect: 'none',
                      WebkitUserSelect: 'none',
                      '&:hover': { cursor: 'pointer', textDecoration: 'underline' },
                    }}
                  >
                    Description:
                  </Box>
                  <Typography ml="1em">{product.description}</Typography>
                </details>
              )}
              <details open data-cy="product-features">
                <Box
                  component="summary"
                  sx={{
                    fontWeight: 'bold',
                    userSelect: 'none',
                    WebkitUserSelect: 'none',
                    '&:hover': {
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    },
                  }}
                >
                  Product Features:
                </Box>
                <ul style={{ margin: 0, paddingInlineStart: '2em' }}>
                  {product.features.map((feature) => (
                    <li key={feature.id}>
                      <ProductFeatureDisplay feature={feature.description} />
                    </li>
                  ))}
                </ul>
              </details>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            pb: '0.5em',
          }}
        >
          <Button
            data-cy="close-details"
            color="primary"
            variant="contained"
            onClick={onClose}
            startIcon={<KeyboardArrowLeftIcon />}
            sx={{
              borderRadius: '0.25em',
              '&:hover': { backgroundColor: 'primary.main', color: 'primary.contrastText' },
            }}
          >
            BACK
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default ProductDialog;
