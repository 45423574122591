import React from 'react';
import { useSnackbar } from 'notistack';

function useAlertSnackbar(variant, autoHideDuration) {
  const [message, setMessage] = React.useState(null);
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  const handleMessageChange = React.useCallback(
    (newMessage) => {
      setMessage(newMessage);
      if (newMessage) {
        const key = enqueueSnackbar(newMessage, {
          SnackbarProps: {
            'data-cy': `${variant}-snackbar`,
            onClick: () => {
              setMessage(null);
              closeSnackbar(key);
            },
          },
          variant,
          autoHideDuration,
          persist: !autoHideDuration,
          style: { whiteSpace: 'pre-line' },
        });
      }
    },
    [autoHideDuration, closeSnackbar, enqueueSnackbar, variant]
  );

  return React.useMemo(() => [message, handleMessageChange], [handleMessageChange, message]);
}

export default useAlertSnackbar;
