import React from 'react';
import { SnackbarProvider } from 'notistack';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

import { PageProvider } from '../contexts/PageContext.jsx';
import { SearchProvider } from '../contexts/SearchContext.jsx';
import TopBar from './TopBar.jsx';

function Layout({ children }) {
  return (
    <SnackbarProvider maxSnack={4}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <SearchProvider>
          <PageProvider>
            <CssBaseline />
            <TopBar />
            <Toolbar />
            <Box
              sx={{
                padding: (theme) => theme.spacing(1),
                paddingTop: 0,
                backgroundColor: (theme) => theme.palette.background.default,
                marginLeft: 0,
                height: '100%',
                width: '100%',
              }}
            >
              {children}
            </Box>
          </PageProvider>
        </SearchProvider>
      </LocalizationProvider>
    </SnackbarProvider>
  );
}

export default Layout;
