import React from 'react';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

import MagnifiableImage from './MagnifiableImage.jsx';
import ProductPictureStepper from './ProductPictureStepper.jsx';

function ExpandableImage({ src, style, alt, onClick, images, open, onClose, defaultStep = 0 }) {
  return (
    <>
      <MagnifiableImage src={src} style={style} alt={alt} onClick={onClick} />
      <Modal
        data-cy="expandable-image-modal"
        open={open}
        sx={{ maxHeight: '50vh', maxWidth: '50vw', mx: 'auto', mt: '5vh' }}
        slotProps={{
          backdrop: {
            sx: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
          },
        }}
      >
        <ProductPictureStepper
          images={images}
          productName={alt}
          width="40vw"
          defaultStep={defaultStep}
        >
          <Box
            display="flex"
            justifyContent="center"
            onClick={(event) => event.stopPropagation()}
            sx={{ maxHeight: '50vh' }}
          >
            <Button
              onClick={onClose}
              color="secondary"
              variant="contained"
              startIcon={<KeyboardArrowLeftIcon />}
              sx={{
                textAlign: 'center',
                my: 1,
                width: 'fit-content',
                borderRadius: '0.25em',
                '&:hover': { backgroundColor: 'secondary.main', color: 'secondary.contrastText' },
              }}
            >
              BACK
            </Button>
          </Box>
        </ProductPictureStepper>
      </Modal>
    </>
  );
}

export default React.memo(ExpandableImage);
