import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';

import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { alpha, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import SearchDialog from './SearchDialog.jsx';

const TRANSPARENT_BACKGROUND_COLOR = 'rgba(0, 0, 0, 0)';

function TopBar({
  // Props
  onClose,
}) {
  const prefersDark = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = useTheme();
  const { user, logout } = useAuth0();
  const ultraNarrow = useMediaQuery('(max-width:399px)');

  const handleClick = React.useCallback(
    () => logout({ logoutParams: { returnTo: window.location.origin } }),
    [logout]
  );

  const transparentColor = React.useMemo(
    () => alpha(theme.palette.background.paper, 0.8),
    [theme.palette.background.paper]
  );

  const [backgroundColor, setBackgroundColor] = React.useState(theme.palette.background.default);
  const [blur, setBlur] = React.useState(null);

  const [boxShadow, setBoxShadow] = React.useState(0);
  const topBarRef = React.useRef(null);

  React.useEffect(() => {
    const handleScroll = () => {
      const { height } = topBarRef.current.getBoundingClientRect();
      if (window.scrollY > height) {
        setBackgroundColor(transparentColor);
        setBlur('blur(10px)');
        setBoxShadow(1);
      } else {
        setBackgroundColor(theme.palette.background.default);
        setBlur(null);
        setBoxShadow(0);
      }
    };
    document.addEventListener('scroll', handleScroll);

    return () => document.removeEventListener('scroll', handleScroll);
  }, [topBarRef, transparentColor, theme.palette.background.default, prefersDark]);

  return (
    <AppBar
      position="fixed"
      sx={{
        boxShadow,
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: TRANSPARENT_BACKGROUND_COLOR,
        color: theme.palette.text.primary,
      }}
      ref={topBarRef}
    >
      <Toolbar sx={{ backgroundColor, backdropFilter: blur }}>
        <Grid container spacing={3} justifyContent="space-between" alignItems="center">
          <Grid item xs="auto">
            <Stack direction="row" alignItems="center">
              <img src="/favicon.ico" alt="TZ" height="32px" />
              <Typography variant="h6" noWrap component="div" sx={{ ml: '0.5em', opacity: '0.7' }}>
                Mattress Catalog
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs onClick={onClose}>
            <SearchDialog />
          </Grid>
          {!ultraNarrow && (
            <Grid item xs="auto">
              <Avatar alt={user?.name} src={user?.picture} onClick={handleClick} />
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;

TopBar.propTypes = {
  onClose: PropTypes.func,
};
