/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useForm } from 'react-hook-form';

import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import axios from '../axiosClient.js';
import SearchContext from '../contexts/SearchContext.jsx';
import useAlertSnackbar from '../hooks/useAlertSnackbar.jsx';
import ModalSearchInput from './ModalSearchInput.jsx';

const SlideTransition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} mountOnEnter unmountOnExit {...props} />
));

function SearchDialog() {
  const mobile = useMediaQuery('(max-width:600px)');
  const { setSearch, setSearchOpen, searchOpen } = React.useContext(SearchContext);

  const [, setSnackbarError] = useAlertSnackbar('error', 0);
  const [modelOptions, setModelOptions] = React.useState([]);

  const [categoryOptions, setCategoryOptions] = React.useState([]);
  const [manufacturerOptions, setManufacturerOptions] = React.useState([]);
  const [modelPairings, setModelPairings] = React.useState([]);

  const [filteredModels, setFilteredModels] = React.useState([]);
  const defaultValues = React.useMemo(
    () => ({
      product: '',
      manufacturerId: '',
      model: '',
      categoryId: '',
      foamType: '',
      thickness: '',
      length: '',
      width: '',
    }),
    []
  );

  const { control, handleSubmit, reset, watch, setValue } = useForm({ defaultValues });
  const selectedManufacturerId = watch('manufacturerId');

  React.useEffect(() => {
    if (selectedManufacturerId) {
      const uniqueModels = [...new Set(modelPairings[selectedManufacturerId])];
      const sortedUniqueModels = uniqueModels.toSorted((a, b) => a.localeCompare(b, 'en'));
      setFilteredModels(sortedUniqueModels.map((model) => ({ value: model, label: model })));
    } else {
      setFilteredModels(modelOptions);
    }
    setValue('model', '');
  }, [modelPairings, modelOptions, selectedManufacturerId, setValue]);

  const onSubmit = React.useCallback(
    (data) => {
      const nonEmptyValues = Object.fromEntries(Object.entries(data).filter(([, value]) => value));
      const searchString = Object.entries(nonEmptyValues)
        .map(([key, value]) => `${key}:"${value}"`)
        .join(' ')
        .trim();
      setSearch(searchString);

      setSearchOpen(false);
    },
    [setSearch, setSearchOpen]
  );

  const fetchData = React.useCallback(async () => {
    try {
      const [{ data: models }, { data: categories }, { data: manufacturers }] = await Promise.all([
        axios.get('/api/models'),
        axios.get('/api/categories'),
        axios.get('/api/manufacturers'),
      ]);
      const uniqueModelNames = [...new Set(models.map(({ model }) => model))];

      setModelOptions(
        uniqueModelNames
          .map((model) => ({ label: model, value: model }))
          .toSorted((a, b) => a.label.localeCompare(b.label))
      );

      const manufacturerModels = models.reduce((grouping, element) => {
        if (!element.model) {
          return grouping;
        }
        if (grouping[element.manufacturerId]) {
          grouping[element.manufacturerId].push(element.model);
          return grouping;
        }
        return { ...grouping, [element.manufacturerId]: [element.model] };
      }, {});

      setModelPairings(manufacturerModels);
      setCategoryOptions(categories.map((option) => ({ label: option.name, value: option.id })));
      setManufacturerOptions(
        manufacturers.map((option) => ({ label: option.name, value: option.id }))
      );
    } catch (err) {
      setSnackbarError(err.response?.data?.error || 'Something bad happened...');
    }
  }, [setSnackbarError, setModelOptions]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleOpen = React.useCallback(() => setSearchOpen(true), [setSearchOpen]);

  const dialogPaperProps = React.useMemo(
    () => ({ sx: { borderRadius: mobile ? 0 : '0.75em' } }),
    [mobile]
  );

  const handleFormSubmit = React.useCallback(
    (event) => {
      // this ensures the page doesn't get reloaded
      event.preventDefault();
      handleSubmit(onSubmit)();
    },
    [handleSubmit, onSubmit]
  );

  // we need to wrap this function because the `event`
  // parameter gets passed to `reset` if you call it like this:
  // ```
  //   onClick={reset}
  // ```
  const handleClear = React.useCallback(() => reset(), [reset]);

  return (
    <Box display="flex" justifyContent="flex-end">
      <Button
        data-cy="search-button"
        variant="contained"
        color="secondary"
        onClick={handleOpen}
        size="small"
        startIcon={<SearchIcon />}
        sx={{
          px: '0.6666em',
          py: '0.3333em',
          borderRadius: '0.25em',
          '&:hover': {
            color: (theme) => theme.palette.secondary.contrastText,
            backgroundColor: (theme) => theme.palette.secondary.main,
          },
        }}
      >
        Search
      </Button>
      <Dialog
        open={searchOpen}
        fullWidth
        fullScreen={mobile}
        maxWidth="md"
        TransitionComponent={SlideTransition}
        PaperProps={dialogPaperProps}
      >
        <form onSubmit={handleFormSubmit} noValidate>
          <DialogTitle>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="h5">Catalog Search</Typography>
            </Box>
          </DialogTitle>
          <DialogContent sx={{ height: '80%', pt: '1em' }}>
            <Grid container spacing={1.5} alignItems="start">
              <Grid item xs={12} pt="1em">
                <ModalSearchInput
                  control={control}
                  label="Search"
                  fieldName="product"
                  type="text"
                />
              </Grid>
              <Grid item xs={6} pt="1em">
                <ModalSearchInput
                  control={control}
                  label="Bed Manufacturer"
                  fieldName="manufacturerId"
                  type="select"
                  options={manufacturerOptions}
                />
              </Grid>
              <Grid item xs={6} pt="1em">
                <ModalSearchInput
                  control={control}
                  label="Bed Model"
                  fieldName="model"
                  type="select"
                  options={filteredModels}
                />
              </Grid>
              <Grid item xs={6} pt="1em">
                <ModalSearchInput
                  control={control}
                  label="Category"
                  fieldName="categoryId"
                  type="select"
                  options={categoryOptions}
                />
              </Grid>
              <Grid item xs={6} pt="1em">
                <ModalSearchInput
                  control={control}
                  label="Foam Type"
                  fieldName="foamType"
                  type="select"
                  options={[
                    { label: 'Standard', value: 'Standard' },
                    { label: 'Memory Foam', value: 'Memory Foam' },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6} pt="1em">
                <ModalSearchInput
                  control={control}
                  label="Thickness (inches)"
                  fieldName="thickness"
                  type="number"
                />
              </Grid>
              <Grid item xs={12} md={6} pt="1em">
                <ModalSearchInput
                  control={control}
                  label="Length (inches)"
                  fieldName="length"
                  type="number"
                />
              </Grid>
              <Grid item xs={12} md={6} pt="1em">
                <ModalSearchInput
                  control={control}
                  label="Width (inches)"
                  fieldName="width"
                  type="number"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Box width="100%" display="flex" mx="1rem" mb="0.75rem" gap={2}>
              <Button
                data-cy="clear-search"
                color="warning"
                variant="contained"
                onClick={handleClear}
                startIcon={<DeleteIcon />}
                sx={{ borderRadius: '0.25em' }}
                fullWidth
              >
                Clear
              </Button>
              <Button
                type="submit"
                data-cy="confirm-search"
                color="primary"
                variant="contained"
                startIcon={<SearchIcon />}
                sx={{ borderRadius: '0.25em' }}
                fullWidth
              >
                SEARCH
              </Button>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}

export default SearchDialog;
