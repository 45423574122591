import React from 'react';
import { Controller } from 'react-hook-form';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import LinearProgress from '@mui/material/LinearProgress';
import Select from '@mui/material/Select';

function SelectSearchInput({
  // props
  label,
  control,
  fieldName,
  options = [],
}) {
  const labelId = React.useMemo(() => label.replace(' ', '-').toLowerCase(), [label]);

  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field: { onChange, value } }) => {
        return (
          <Box sx={{ pt: 1 }}>
            <FormControl fullWidth>
              <InputLabel id={labelId}>{label}</InputLabel>
              <Select
                data-cy={`${fieldName}-search`}
                fullWidth
                label={label}
                labelId={labelId}
                onChange={onChange}
                variant="outlined"
                sx={{ borderRadius: '0.25rem' }}
                value={value}
                disabled={!options.length}
                native
              >
                <option value="" aria-label="None" />
                {options?.map((option) => {
                  return (
                    <option value={option.value} key={option.value}>
                      {option.label}
                    </option>
                  );
                })}
              </Select>
              {Boolean(options.length) || (
                <LinearProgress data-cy="dropdown-loading-bar" sx={{ mt: 1 }} />
              )}
            </FormControl>
          </Box>
        );
      }}
    />
  );
}

export default SelectSearchInput;
