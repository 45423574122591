import React from 'react';

import NumericSearchInput from './NumericSearchInput.jsx';
import SelectSearchInput from './SelectSearchInput.jsx';
import TextSearchInput from './TextSearchInput.jsx';

function ModalSearchInput({
  // props
  type,
  label,
  fieldName,
  control,
  options = [],
}) {
  if (type === 'number') {
    return <NumericSearchInput control={control} label={label} fieldName={fieldName} />;
  }
  if (type === 'select') {
    return (
      <SelectSearchInput control={control} label={label} fieldName={fieldName} options={options} />
    );
  }
  return <TextSearchInput control={control} label={label} fieldName={fieldName} />;
}

export default ModalSearchInput;
