import React from 'react';

import SearchIcon from '@mui/icons-material/Search';
import WarningIcon from '@mui/icons-material/Warning';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useFilter } from '@tzmedical/react-hooks';

import axios from '../../axiosClient.js';
import SearchContext from '../../contexts/SearchContext.jsx';
import useAlertSnackbar from '../../hooks/useAlertSnackbar.jsx';
import ProductCard from '../ProductCard.jsx';

const PRODUCT_LIMIT = 8;

function Home() {
  const { search, setSearchOpen } = React.useContext(SearchContext);

  const [loading, setLoading] = React.useState(true);
  const [, setSnackbarError] = useAlertSnackbar('error', 0);

  const [products, setProducts] = React.useState(null);

  const keywords = React.useMemo(
    () => ({
      categoryId: 'categoryId',
      foamType: 'foamType',
      length: 'length',
      manufacturerId: 'manufacturerId',
      model: 'model',
      name: 'name',
      product: (product) =>
        product.features
          .flatMap((f) => f.description.split(' '))
          .concat([product.description], [product.name])
          .join(' '),
      thickness: 'thickness',
      width: 'width',
    }),
    []
  );

  const isSearchEmpty = React.useMemo(() => !search, [search]);

  const filteredProducts = useFilter(products || [], search, keywords);

  const fetchProducts = React.useCallback(async () => {
    if (products === null) {
      try {
        const { data } = await axios.get('/api/products');
        setProducts(data);
        setLoading(false);
      } catch (err) {
        setSnackbarError(err.response?.data?.error || 'Something bad happened...');
      }
    } else {
      setLoading(false);
    }
  }, [setSnackbarError, setProducts, products]);

  React.useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const handleClick = React.useCallback(() => setSearchOpen(true), [setSearchOpen]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="fixed"
        top={0}
        left={0}
        height="100vh"
        width="100vw"
      >
        <CircularProgress size={120} />
      </Box>
    );
  }
  return (
    <>
      {filteredProducts?.length > PRODUCT_LIMIT && !isSearchEmpty && (
        <Box
          data-cy="too-many-results-warning"
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={1}
          sx={{
            boxShadow: 1,
            py: 1,
            px: 2,
            backgroundColor: (theme) => theme.palette.warning.main,
            color: (theme) => theme.palette.warning.contrastText,
            zIndex: (theme) => theme.zIndex.modal,
            left: 0,
            bottom: 0,
            position: 'fixed',
            height: 'fit-content',
            width: '100vw',
          }}
        >
          <WarningIcon />
          <Typography variant="body1" color={(theme) => theme.palette.warning.contrastText}>
            Too many results fit your search parameters. Only the top {PRODUCT_LIMIT} out of{' '}
            {filteredProducts?.length} results will be displayed.
          </Typography>
        </Box>
      )}
      {isSearchEmpty && (
        <Box
          height="100vh"
          paddingBottom="8em"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" textAlign="center">
            Welcome to TZ Medical&apos;s Mattress Catalog!
          </Typography>
          <Typography textAlign="center" variant="subtitle1">
            Click the button below to search.
          </Typography>
          <Button
            data-cy="empty-search-button"
            onClick={handleClick}
            variant="contained"
            color="secondary"
            size="large"
            startIcon={<SearchIcon />}
            sx={{
              mx: 'auto',
              mt: '1em',
              width: 'fit-content',
              borderRadius: '0.25em',
            }}
          >
            Search
          </Button>
        </Box>
      )}
      {!isSearchEmpty && (
        <Grid
          container
          columns={12}
          justifyContent="center"
          height="100%"
          width={{ xs: '100%', md: '85%' }}
          mx="auto"
          pb={{ xs: '6em', md: '4em' }}
          pt="0.5em"
        >
          {!filteredProducts?.length && (
            <Grid item xs display="flex" justifyContent="center" alignItems="center">
              <Typography variant="h5" color="text.secondary" sx={{ fontStyle: 'italic' }}>
                No results were found.
              </Typography>
            </Grid>
          )}
          {filteredProducts?.slice(0, PRODUCT_LIMIT).map((product, index) => (
            <Grid item xs={12} md={6} lg={3} py={0.5} key={`product-card-container-${product.id}`}>
              <ProductCard product={product} index={index} total={filteredProducts.length} />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
}

export default Home;
