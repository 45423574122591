import React from 'react';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import UhOhRobot from '../assets/404-robot.svg';

function ErrorPage() {
  return (
    <Container>
      <Grid
        container
        spacing={12}
        alignItems="center"
        minWidth="100%"
        minHeight="70vh"
        display="flex"
        justifyContent="center"
        flexDirection="row"
        justify="center"
      >
        <Grid item xs={12} sm={6}>
          <h1>404 Page Not Found</h1>
          <Typography variant="subtitle1">
            Sorry, the resource you were looking for cannot be found.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <img src={UhOhRobot} alt="404" />
        </Grid>
      </Grid>
    </Container>
  );
}

export default ErrorPage;
