import React from 'react';
import PropType from 'prop-types';

const SearchContext = React.createContext();

export function SearchProvider({ children }) {
  const [search, setSearch] = React.useState('');
  const [searchOpen, setSearchOpen] = React.useState(false);
  const value = React.useMemo(
    () => ({ search, setSearch, searchOpen, setSearchOpen }),
    [search, setSearch, searchOpen, setSearchOpen]
  );

  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
}

export default SearchContext;

SearchProvider.propTypes = {
  children: PropType.node.isRequired,
};
