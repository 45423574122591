import React from 'react';

import Typography from '@mui/material/Typography';

function DimensionsDisplay({
  // props
  width,
  length,
  thickness,
}) {
  return (
    <>
      {Boolean(length) && (
        <Typography variant="subtitle1" data-cy="product-length">
          <strong>Length:</strong> {length}&quot;
        </Typography>
      )}
      {Boolean(width) && (
        <Typography variant="subtitle1" data-cy="product-width">
          <strong>Width:</strong> {width}&quot;
        </Typography>
      )}
      {Boolean(thickness) && (
        <Typography variant="subtitle1" data-cy="product-thickness">
          <strong>Thickness:</strong> {thickness}&quot;
        </Typography>
      )}
    </>
  );
}

export default React.memo(DimensionsDisplay);
