import React from 'react';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import MagnifiableImage from '../components/MagnifiableImage.jsx';
import ProductPictureStepper from '../components/ProductPictureStepper.jsx';

function ProductImageMasonry({ product }) {
  const sortedImages = React.useMemo(
    () => product.images.toSorted((a, b) => a.URL.localeCompare(b.URL, 'en')),
    [product.images]
  );
  const [defaultStep, setDefaultStep] = React.useState(0);

  const [open, setOpen] = React.useState(false);
  const handleImageClick = React.useCallback(() => setOpen(true), []);
  const blockClickCascade = React.useCallback((event) => event.stopPropagation(), []);
  const handleClose = React.useCallback(() => setOpen(false), []);

  return (
    <Box>
      {sortedImages.map(({ URL }, index) => (
        <MagnifiableImage
          key={URL}
          style={{
            height: '7em',
            display: 'inline-block',
            marginLeft: '0.25em',
            marginRight: '0.25em',
          }}
          index={index}
          src={URL}
          alt={product.name}
          onClick={(event) => {
            event.stopPropagation();
            setDefaultStep(index);
            handleImageClick(event);
          }}
        />
      ))}
      <Dialog
        data-cy="image-stepper-dialog"
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { borderRadius: '0.75rem' } }}
        onClick={blockClickCascade}
      >
        <DialogContent sx={{ width: '100%' }}>
          <ProductPictureStepper
            images={sortedImages}
            productName={product?.name}
            defaultStep={defaultStep}
            width="100%"
          />
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            data-cy="image-stepper-dialog-back-button"
            onClick={handleClose}
            color="secondary"
            variant="contained"
            startIcon={<KeyboardArrowLeftIcon />}
            sx={{
              borderRadius: '0.25em',
              '&:hover': { backgroundColor: 'secondary.main', color: 'secondary.contrastText' },
            }}
          >
            Back
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default React.memo(ProductImageMasonry);
