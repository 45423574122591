import React from 'react';
import { Controller } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

function NumericSearchInput({
  // props
  label,
  control,
  fieldName,
}) {
  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl
          fullWidth
          sx={{ pt: 1, display: 'flex', flexDirection: 'row', borderRadius: '0.5em' }}
        >
          <TextField
            data-cy={`${fieldName}-search`}
            type="number"
            variant="outlined"
            label={label}
            size="sm"
            value={value}
            onChange={onChange}
            sx={{ flex: 1 }}
          />
        </FormControl>
      )}
    />
  );
}

export default NumericSearchInput;
