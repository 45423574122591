import React from 'react';
import { Controller } from 'react-hook-form';

import SearchIcon from '@mui/icons-material/Search';

import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

function TextSearchInput({
  // props
  label,
  control,
  fieldName,
}) {
  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field: { onChange, value } }) => {
        return (
          <FormControl fullWidth sx={{ pt: 1, display: 'flex', flexDirection: 'row' }}>
            <TextField
              data-cy={`${fieldName}-search`}
              fullWidth
              onChange={onChange}
              type="text"
              variant="outlined"
              value={value}
              label={label}
              size="sm"
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <SearchIcon />
                  </InputAdornment>
                ),
                sx: { borderRadius: '0.25em' },
              }}
            />
          </FormControl>
        );
      }}
    />
  );
}

export default TextSearchInput;
